.root {
    /* css here */
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 12px;
}

.step-wrapper {
    flex: 1 1 0px;
}

.step-icon-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.step-icon-default {
    height: 30px;
    width: 30px;
    border: 1px solid var(--neutral-black);
    background-color: var(--system-info-dark);
    border-radius: 50%;
}

.step-separator {
    height: 2px;
    background-color: var(--stepper-icon-separator-color);
    width: 100%;
}

.step-separator-active {
    background-color: var(--stepper-icon-separator-active-color);
}

.step-label {
    margin-top: 8px;
}

.label {
    white-space: nowrap;
}
