:root {
    --default-font-size: 12px;
    --default-increase-value: 3px;
    --default-fontSize-percentage: 70%;
    --default-increase-percentage: 20%;
    --h2-size: 4px;
    --h5-size: 2px;
    --line-height-size: 3.5px;

    --small-fontSize: var(--default-font-size);
    --small-fontSize-percentage: var(--default-fontSize-percentage);
    --small-h2-fontSize: calc(var(--small-fontSize) + var(--h2-size));
    --small-h5-fontSize: calc(var(--small-fontSize) + var(--h5-size));
    --small-line-height: calc( var(--small-fontSize) + var(--line-height-size));

    --medium-fontSize: calc(var(--default-font-size) + var(--default-increase-value));
    --medium-fontSize-percentage: calc(var(--default-fontSize-percentage) + var(--default-increase-percentage));
    --medium-h2-fontSize: calc(var(--medium-fontSize) + var(--h2-size));
    --medium-h5-fontSize: calc(var(--medium-fontSize) + var(--h5-size));
    --medium-line-height: calc( var(--medium-fontSize) + calc(var(--line-height-size) * 1.8));

    --large-fontSize: calc(var(--default-font-size) + calc( var(--default-increase-value) * 1.35));
    --large-fontSize-percentage: calc(var(--default-fontSize-percentage) + ( var(--default-increase-percentage) * 2));
    --large-h2-fontSize: calc(var(--large-fontSize) + var(--h2-size));
    --large-h5-fontSize: calc(var(--large-fontSize) + var(--h5-size));
    --large-line-height: calc( var(--large-fontSize) + calc(var(--line-height-size) * 2.1));
 
    --dark--font-color: #ffffff;
    --dark-bgColor: rgb(45,45,45);
    --dark-appBackground : #323232;
    --dark-card-bgColor:rgb(50,50,50);

    --yellow--font-color: yellow;
    --default-bgColor: #FFF3F3;
}