.container {
    display: flex;
    justify-content: space-between;
  }
   
  .column {
    flex: 1;
    padding: 5px;
    text-align: left;
  }
   
  .card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .soundBlockNew {
    height: auto;
    margin-top: 10px;
    
    * {
      margin: 0;
    }

    svg {
      max-height: 13vh;
      max-width: 15vh;
    }
    .middleSvg {
      svg {
        max-height: 48px;
        max-width: 48px;
        cursor: pointer;
        color: var(--audio-visualizer-color);
      }
    }
    .imageBlock {
      text-align: center;
      aspect-ratio: 0;
      margin-top: 8px;
      height: 48px;
    }
    @media only screen and (max-width:750px) {
      margin: 0px 5%;
    }
    .centerContent{
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .centerContent:not(:last-child) {
      margin-bottom: 20px;
    }
    .progressCard {
      width: 450px;
      margin: 0 auto 20px auto;
      padding: 20px;
      border: 2px solid;
      border-color: var(--timer-progress-bar-container-border-color);
    }
  }
