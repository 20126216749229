
:root {
    /* Colors token */
    --primary-red: #e31837;
    --secondary-burgundy: #a61242;
    --secondary-aubergine: #8a0f4a;
    --secondary-purple: #700f4f;
    --secondary-ochre: #f58c1f;
    --secondary-dark-blue: #240859;
    --neutral-white: #ffffff;
    --neutral-grey-100: #fafafa;
    --neutral-grey-200: #f6f6f6;
    --neutral-grey-300: #e6e9ec;
    --neutral-grey-400: #adabab;
    --neutral-grey-500: #878787;
    --neutral-grey-600: #555555;
    --neutral-grey-700: #333541;
    --neutral-grey-800: #181818;
    --neutral-black: #000000;
    --contrast-contrast: #ffff00;
    --contrast-focus: #ffd831;
    --contrast-error: #d29a83;
    --contrast-light: #cce5ff;
    --system-success-dark: #027623;
    --system-success-light: #cef2d8;
    --system-warning-dark: #8d360a;
    --system-warning-light: #fff3d6;
    --system-info-dark: #1260f0;
    --system-info-light: #e5eeff;
    --system-error-dark: #b71c1c;
    --system-error-light: #ffcdd2;
    --semantic-bright-red: #f44D61;

    /* Font token  */
    --font-size-12: 12px;
    --font-size-14: 14px;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-20: 20px;
    --font-size-22: 22px;
    --font-size-24: 24px;
    --font-size-28: 28px;
    --font-size-32: 32px;

    --font-line-height-16: 16px;
    --font-line-height-18: 18px;
    --font-line-height-20: 20px;
    --font-line-height-22: 22px;
    --font-line-height-24: 24px;
    --font-line-height-28: 28px;
    --font-line-height-32: 32px;
    --font-line-height-36: 36px;
    --font-line-height-40: 40px;

    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;

    /* Sizes */
    --button-height-xl: 56px;
    --button-height-lg: 48px;
    --button-height-md: 36px;
    --button-height-sm: 26px;

    --button-width-xl: 150px;
    --button-width-lg: 144px;
    --button-width-md: 122px;
    --button-width-sm: 92px;

    --button-icon-size: 24px;
    --button-icon-size-sm: 16px;

    /* Spacing */

    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-sm: 12px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    

    --padding-2xl: 40px;

    /* utils */
    --radius-8px: 8px;

    --shadow-sm: 0px 4px 10px 0px rgba(57, 65, 77, 0.05);
    --shadow-md-bottom: 0px 15px 4px 0px rgba(0, 0, 0, 0), 0px 10px 4px 0px rgba(0, 0, 0, 0.01),
        0px 5px 3px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09),
        0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    --shadow-md-top: 0px -12px 2px 0px rgba(0, 0, 0, 0), 0px -8px 2px 0px rgba(0, 0, 0, 0.01),
        0px -2px 2px 0px rgba(0, 0, 0, 0.09);
    --shadow-lg: 18px 38px 12px 0px rgba(0, 0, 0, 0), 12px 24px 11px 0px rgba(0, 0, 0, 0.01),
        7px 14px 9px 0px rgba(0, 0, 0, 0.05), 3px 6px 7px 0px rgba(0, 0, 0, 0.09),
        1px 2px 4px 0px rgba(0, 0, 0, 0.1);

    /* Semantics Tokens */

    --heading-font-size-xs: var(--font-size-16);
    --heading-font-size-line-height-xs: var(--font-line-height-18);
    --heading-font-size-sm: var(--font-size-18);
    --heading-font-size-line-height-sm: var(--font-line-height-22);
    --heading-font-size-md: var(--font-size-20);
    --heading-font-size-line-height-md: var(--font-line-height-28);
    --heading-font-size-lg: var(--font-size-24);
    --heading-font-size-line-height-lg: var(--font-line-height-32);
    --heading-font-size-xl: var(--font-size-28);
    --heading-font-size-line-height-xl: var(--font-line-height-36);
    --heading-font-size-xxl: var(--font-size-32);
    --heading-font-size-line-height-xxl: var(--font-line-height-40);

    --heading-font-regular: var(--font-weight-400);
    --heading-font-medium: var(--font-weight-500);
    --heading-font-semibold: var(--font-weight-600);
    --heading-font-bold: var(--font-weight-700);

    --body-font-size-xss: var(--font-size-12);
    --body-font-line-height-xss: var(--font-line-height-16);
    --body-font-size-xs: var(--font-size-14);
    --body-font-line-height-xs: var(--font-line-height-20);
    --body-font-size-sm: var(--font-size-16);
    --body-font-line-height-sm: var(--font-line-height-24);
    --body-font-size-md: var(--font-size-18);
    --body-font-line-height-md: var(--font-line-height-28);
    --body-font-size-lg: var(--font-size-22);
    --body-font-line-height-lg: var(--font-size-32);


    --font-regular: var(--font-weight-400);
    --font-medium: var(--font-weight-500);
    --font-semibold: var(--font-weight-600);
    --font-bold: var(--font-weight-700);
    --font-extrabold: var(--font-weight-800);

    --btn-box-shadow: 0px 15px 4px 0px rgba(0, 0, 0, 0), 0px 10px 4px 0px rgba(0, 0, 0, 0.01),
        0px 5px 3px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09),
        0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    --btn-focus-shadow: 0px 0px 0px 2px #ffd831, 0px 0px 0px 4px #ffd831;

    --heading-font: "Work Sans";
    --body-font: "Open Sans";

}
