.root {
    cursor: pointer;
    font-family: var(--body-font);
    display: flex;
    justify-content: center;
    font-weight: var(--font-bold);
    gap: var(--spacing-xxs);
    outline: none;
}

.primary {
    background-color: var(--primary-btn-bg-color);
    color: var(--primary-btn-text-color);
    border: 1px solid var(--primary-btn-border-color);
}

.secondary {
    background-color: var(--secondary-btn-bg-color);
    color: var(--secondary-btn-text-color);
    border: 2px solid var(--secondary-btn-border-color);
}

.sm {
    height: var(--button-height-sm);
    min-width: var(--button-width-sm);
    padding: var(--spacing-xxs) var(--spacing-xs);
    font-size: var(--font-size-12);
    line-height: 1.2;
    align-items: center;
}

.md {
    height: var(--button-height-md);
    min-width: var(--button-width-md);
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-14);
    line-height: 1.2;
    align-items: center;
}

.lg {
    height: var(--button-height-lg);
    min-width: var(--button-width-lg);
    font-size: var(--font-size-16);
    padding: var(--spacing-sm) var(--spacing-md);
}

.xl {
    height: var(--button-height-xl);
    min-width: var(--button-width-xl);
    font-size: var(--font-size-16);
    padding: var(--spacing-md) var(--spacing-lg);
}

.primary:hover {
    background-color: var(--primary-btn-hover-bg-color);
    color: var(--primary-btn-hover-text-color);
    border: 1px solid var(--primary-btn-hover-border-color);
    opacity:  var(--primary-btn-hover-opacity);;
}

.secondary:hover {
    background-color: var(--secondary-btn-hover-bg-color);
    color: var(--secondary-btn-hover-text-color);
    border: 2px solid var(--secondary-btn-hover-border-color);
}

.primary.disabled,
.primary.loading {
    cursor: not-allowed;
    background-color: var(--primary-btn-disabled-bg-color);
    color: var(--primary-btn-disabled-text-color);
    border: 1px solid var(--primary-btn-disabled-border-color);
}

.secondary.disabled,
.secondary.loading {
    cursor: not-allowed;
    background-color: var(--secondary-btn-loading-bg-color);
    color: var(--secondary-btn-loading-text-color);
    border: 2px solid var(--secondary-btn-loading-border-color);
}

.primary:active:not(:disabled) {
    background-color: var(--primary-btn-pressed-bg-color);
    color: var(--primary-btn-pressed-text-color);
    border: 1px solid var(--primary-btn-pressed-border-color);
}

.secondary:active:not(:disabled) {
    background-color: var(--secondary-btn-pressed-bg-color);
    color: var(--secondary-btn-pressed-text-color);
    border: 2px solid var(--secondary-btn-pressed-border-color);
}

.primary:focus {
    box-shadow: var(--primary-btn-focus-shadow);
}

.secondary:focus {
    box-shadow: var(--secondary-btn-focus-shadow);
}

.spinner,
.icon {
    width: var(--button-icon-size); /* Default for md, lg, xl */
    height: var(--button-icon-size);
}

.spinnerSmall,
.iconSmall {
    width: var(--button-icon-size-sm);
    height: var(--button-icon-size-sm);
}

/* Storybook */

.container {
    display: inline-flex;
    gap: 64px;
}

.section {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
}

.header {
    color: var(--storybook-text-color);
}
