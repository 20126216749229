.root {
    display: flex;
    align-items: center;
}

.colorReference {
    color: var(--audio-visualizer-color);
    display: none;
}

/* storybook */

.header{
    color: var(--storybook-text-color);
    margin: 20px 0
}