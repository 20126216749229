body {
  background-color: #f7f7f7;
  height: 100%;
}
.login_main_container {
  height: auto;
  margin: 10px 20%;
  justify-content: center;
}
.login_container .MuiContainer-root {
  margin-top: 50px;
}
.login_container {
  max-width: 520px;
  width: 100%;
  margin-top: 20px;
}
.logo_container {
  height: 42;
  margin-top: 20px
}
