.root {

    font-family: var(--body-font);
    display: grid;
    gap: 4px;
    position: relative;

    .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .dropdown-wrapper {
        position: relative;
    }

    input {
        padding: var(--spacing-xs) calc(var(--spacing-sm) + 30px) var(--spacing-xs) var(--spacing-sm);
        font-size: var(--body-font-size-sm);
        line-height: var(--body-font-line-height-sm);
        outline: none;
        font-family: var(--body-font);
        border: 1px solid var(--input-border-color);
        box-sizing: border-box;
        width: 100%;
    }

    input:hover {
        border: 1px solid var(--input-border-hover-color);
    }

    input:focus:not(:disabled) {
        border: 1px solid var(--input-border-active-color);
        box-shadow: var(--input-border-active-outline);
    }

    .error {
        border: 1px solid var(--input-border-error-color);
    }

    .error-label {
        color: var(--input-border-error-color);
    }

    .icon {
        position: absolute;
        box-sizing: border-box;
        right: var(--spacing-sm);
        height: 24px;
        cursor: pointer;
    }
}
