.root {
    padding: 40px;
    box-shadow: var(--shadow-sm);
    background-color: var(--card-bg-color);
    box-sizing: border-box;
}

/* storybook */

.container {
    text-align: center;
    display: flex;
    gap: 32px;
    align-items: start;
    color: var(--storybook-text-color);
}

.header{
    text-align: center;
    color: var(--storybook-text-color);
}

.customPadding {
    padding: 48px 72px;
}

.customHeight {
    min-height: 300px;
    max-width: 600px;
}
