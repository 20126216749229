.root {
    display: grid;
    column-gap: 8px;
    row-gap: 4px;
    cursor: pointer;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;

    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    line-height: initial;
}

.description {
    grid-row: 1 / 2; /* Same row as the radio, second column */
    grid-column: 2 / 3;
    align-self: center;
    font-size: var(--font-size-18);
    color: var(--radio-btn-text-color);
    font-family: var(--heading-font);
    font-weight: var(--font-semibold);
}

.helperText {
    grid-row: 2 / 3; /* Second row, second column */
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    font-family: var(--body-font);
    font-size: var(--font-size-16);
    font-weight: var(--font-regular);
    color: var(--radio-btn-helper-text-color);
}

.helperIcon {
    width: 20px;
    height: 20px;
}


.radio {
    appearance: none; /* Remove native radio appearance */
    -webkit-appearance: none; /* for Safari/older browsers */

    grid-row: 1 / 2; /* Only occupy the first row */
    grid-column: 1 / 2;
    align-self: center; /* Center the radio vertically in its row */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--radio-btn-border-color);
    background-color: var(--unchecked-radio-btn-bg-color);
    cursor: pointer;
    position: relative;
}

/* Checked state */
.radio:checked {
    background-color: var(--checked-radio-btn-bg-color);
    border-color: var(--radio-btn-border-color);
}

/* Hover state */
.radio:not(:disabled):hover,
.root:not(.disabled):hover .radio {
    background-color: var(--unchecked-radio-btn-hover-bg-color);
    opacity: var(--radio-btn-hover-opacity);
}

.radio:checked:not(:disabled):hover,
.root:not(.disabled):hover .radio:checked {
    background-color: var(--checked-radio-btn-hover-bg-color);
    opacity: var(--radio-btn-hover-opacity);
}

/* Error state */
.radio.error:not(:checked) {
    background-color: var(--unchecked-radio-btn-error-bg-color);
    border-color: var(--radio-btn-error-border-color);
}

.radio.error:checked {
    background-color: var(--checked-radio-btn-error-bg-color);
    border-color: var(--radio-btn-error-border-color);
}

.helperText.error {
    margin-top: 0;
    margin-left: -2px;
}

.helperText.error,
.helperText.error > * {
    color: var(--radio-btn-error-helper-text-color) !important;
}

/* Focus state*/
.radio:focus {
    outline: var(--radio-btn-focus-outline);
    outline-offset: var(--radio-btn-focus-outline-offset);
}

/* Disabled state */
.root.disabled {
    cursor: not-allowed;
}

.radio:disabled {
    cursor: not-allowed;
    border-color: var(--radio-btn-disabled-border-color);
    background-color: var(--unchecked-radio-btn-disabled-bg-color);
}

.radio:checked:disabled {
    background-color: var(--checked-radio-btn-disabled-bg-color);
    border-color: var(--radio-btn-disabled-border-color);
}

.radio:checked:disabled::before {
    background-color: var(--radio-btn-disabled-dot-color);
}

/* Dot inside the circle */
.radio:checked::before {
    content: '';
    display: block;
    width: 6.5px;
    height: 6.5px;
    border-radius: 50%;
    background-color: var(--radio-btn-dot-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Storybook */

.container {
    display: inline-flex;
    gap: 64px;
}

.section {
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
}

.header {
    color: var(--storybook-text-color);
}
