.root {
    /* css here */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-bg-color);
    padding: 10px 106px;
    border-bottom: 1px solid var(--header-border-color);
}

.text,
.root > .text {
    color: var(--header-text-color);
}

.logo {
    color: var(--neutral-white);
}
