.root {
    padding: 12px !important;
}

.canvas {
    width: 100%;
    height: 100%;
}

.talking {
    outline: 3px solid var(--avatar-scene-talking-outline-color);
    outline-offset: -3px;
}

/* storybook */

.card {
    width: 700px;
    height: 500px;
}

.container {
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
}
