.root {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* storybook */

.header{
    margin: 20px 0;
    color: var(--text-color);
}