.root {
    /* css here */
}

.timerProgressBg {
    height: 8px;
    background: var(--timer-progress-bar-bg-color);
    border-radius: 2px;
}
.timerBarCard{
    margin: 0;
    margin-top: 20px;
    padding: 12px;
}
.timeMsgColor{
    color: var(--timer-progress-bar-color);
}
.SoundTimerGrid {
    display: grid;
    grid-template-columns: 14% 71% 15%;
    margin: 0px 5%;
}
.soundTimerCard {
    box-shadow: 0px 0px;
}
.progressBarColor{
    height: 100%;
    transition: "width 0.1s linear";
    background: var(--timer-progress-bar-color);
    border-radius: 2px;
}
.centerTime{
    text-align: center;
    margin: auto;
}
.noMargin{
    margin-top: 0px;
}