.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 80vh;
    overflow-y: auto;
    padding: 100px 0;
}

.mainAvatarContainer {
    display: grid;
    gap: 12px;
}

.mainAvatar {
    width: 800px;
    height: 450px;
}

.endMessageCard {
    height: 600px;
    width: 950px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* part 2 */

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 340px;
    grid-template-rows: 300px 66px 100px;
    column-gap: 40px;
}

.instructions{
    grid-row: 1 / 3;
    grid-column: 1 / 2; 
}

.timerProgressBar{
    grid-row: 3 / 4;
    grid-column: 1 / 2;
}

.sideAvatar {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
}

.sideRecordingBar{
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    margin-top: 12px;
}

.timer {
    display: flex;
    gap: 35px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-row: 1 / 3;
    grid-column: 2 / 3;
}