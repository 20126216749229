.root {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px !important;
}

.text {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    align-items: center;
    margin-left: auto;
}

.icon {
    color: var(--recording-bar-icon-color);
    width: 12px;
    height: 12px;
}

.iconRecording {
    animation: 1s blink ease infinite;
}

@keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

/* storybook */

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}


.card {
    width: 500px;
}
