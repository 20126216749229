.root {
    position: relative;
    font-family: var(--body-font);
    color: var(--neutral-black);

    .dropdown-popup {
        background-color: var(--neutral-white);
        top: 45px;
        width: 100%;
        border: 1px solid var(--neutral-grey-400);
        position: absolute;
    }

    .dropdown-item {
        padding: var(--spacing-xs) var(--spacing-md);
        border-bottom: 1px solid var(--neutral-grey-400);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: var(--neutral-black);
    }

    .dropdown-input:hover {
        border: 1px solid var(--dropdown-border-color);
    }

    .dropdown-active-state {
        box-shadow: var(--input-border-active-outline);
    }
}
