@import url('./design-variables.css');

[data-theme='white_on_black'] {
    --background-color: var(--neutral-grey-800);
    --text-color: var(--neutral-white);

    --text-color-disabled: var(--neutral-grey-500);

    /* storybook tokens*/
    --storybook-bg-color: var(--neutral-grey-800);
    --storybook-text-color: var(--neutral-white);

    /* button tokens*/
    --primary-btn-bg-color: var(--neutral-white);
    --primary-btn-hover-bg-color: var(--contrast-light);
    --primary-btn-pressed-bg-color: var(--contrast-light);
    --primary-btn-disabled-bg-color: var(--neutral-grey-500);
    --primary-btn-loading-bg-color: var(--neutral-grey-500);

    --primary-btn-text-color: var(--neutral-black);
    --primary-btn-hover-text-color: var(--neutral-black);
    --primary-btn-pressed-text-color: var(---neutral-black);
    --primary-btn-disabled-text-color: var(--neutral-grey-200);
    --primary-btn-loading-text-color: var(--neutral-grey-200);

    --primary-btn-border-color: var(--neutral-white);
    --primary-btn-hover-border-color: var(---neutral-black);
    --primary-btn-disabled-border-color: var(--neutral-grey-600);
    --primary-btn-loading-border-color: var(--neutral-grey-600);

    --primary-btn-hover-opacity: 0.6;

    --primary-btn-focus-shadow: var(--btn-focus-shadow);
    --secondary-btn-focus-shadow: var(--btn-focus-shadow);

    --secondary-btn-bg-color: var(--neutral-black);
    --secondary-btn-hover-bg-color: var(--neutral-grey-800);
    --secondary-btn-pressed-bg-color: var(--neutral-grey-700);
    --secondary-btn-disabled-bg-color: var(--neutral-black);
    --secondary-btn-loading-bg-color: var(--neutral-black);

    --secondary-btn-text-color: var(--neutral-white);
    --secondary-btn-hover-text-color: var(--contrast-light);
    --secondary-btn-pressed-text-color: var(--contrast-light);
    --secondary-btn-disabled-text-color: var(--neutral-grey-500);
    --secondary-btn-loading-text-color: var(--neutral-grey-500);

    --secondary-btn-border-color: var(--neutral-white);
    --secondary-btn-hover-border-color: var(--contrast-light);
    --secondary-btn-pressed-border-color: var(--contrast-light);
    --secondary-btn-disabled-border-color: var(--neutral-grey-500);
    --secondary-btn-loading-border-color: var(--neutral-grey-500);

    /* spinner tokens*/
    --loading-spinner-color: var(--contrast-light);

    /* radio button tokens*/
    --unchecked-radio-btn-bg-color: var(--neutral-black);
    --unchecked-radio-btn-hover-bg-color: var(--system-info-light);
    --unchecked-radio-btn-focus-bg-color: var(--neutral-black);
    --unchecked-radio-btn-error-bg-color: var(--neutral-black);
    --unchecked-radio-btn-disabled-bg-color: var(--neutral-grey-400);

    --checked-radio-btn-bg-color: var(--neutral-white);
    --checked-radio-btn-hover-bg-color: var(--system-info-light);
    --checked-radio-btn-focus-bg-color: var(--neutral-white);
    --checked-radio-btn-error-bg-color: var(--contrast-error);
    --checked-radio-btn-disabled-bg-color: var(--neutral-grey-400);

    --radio-btn-border-color: var(--neutral-white);
    --radio-btn-hover-border-color: var(--neutral-white);
    --radio-btn-focus-border-color: var(--neutral-white);
    --radio-btn-error-border-color: var(--contrast-error);
    --radio-btn-disabled-border-color: var(--neutral-grey-300);

    --radio-btn-dot-color: var(--neutral-black);
    --radio-btn-disabled-dot-color: var(--neutral-white);

    --radio-btn-hover-opacity: 0.6;

    --radio-btn-focus-outline: 4px solid #ffd831;
    --radio-btn-focus-outline-offset: 0;

    --radio-btn-text-color: var(--neutral-white);

    --radio-btn-helper-text-color: var(--neutral-white);
    --radio-btn-error-helper-text-color: var(--contrast-error);

    --stepper-icon-bg-color: var(--neutral-grey-700);
    --stepper-icon-border-color: var(--neutral-grey-500);
    --stepper-icon-color: var(--neutral-grey-500);
    --stepper-icon-active-bg-color: var(--neutral-white);
    --stepper-icon-active-border-color: var(--neutral-white);
    --stepper-icon-active-color: var(--neutral-black);

    --stepper-icon-separator-color: var(--neutral-grey-700);
    --stepper-icon-separator-active-color: var(--neutral-white);

    /* Timer Progress Bar tokens */
    --timer-progress-bar-color: var(--neutral-white);
    --timer-progress-bar-bg-color: var(--neutral-grey-500);
    --timer-progress-bar-container-border-color: var(--neutral-white);

    /* card tokens*/
    --card-bg-color: var(--neutral-black);

    /* audio visualizer tokens*/
    --audio-visualizer-color: var(--neutral-white);

    /*header tokens */
    --header-bg-color: var(--neutral-black);
    --header-text-color: var(--neutral-white);
    --header-border-color: var(--neutral-white);

    /*footer tokens */
    --footer-bg-color: var(--neutral-black);
    --footer-border-color: var(--neutral-white);

    --input-border-color: var(--neutral-grey-400);
    --input-border-hover-color: var(--neutral-black);
    --input-border-active-color: var(--neutral-black);
    --input-border-active-outline: 0px 0px 0px 2px #000, 0px 0px 0px 4px #fff;
    --input-border-error-color: var(--contrast-error);

    --dropdown-border-color: var(--neutral-grey-400);
    
    /* recording bar tokens */
    --recording-bar-icon-color: var(--neutral-white);

    /* avatar scene tokens */
    --avatar-scene-talking-outline-color: var(--neutral-white);
}
