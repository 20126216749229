.root {
    fill: var(--loading-spinner-color);
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Storybook */

.container {
    text-align: center;
    display: flex;
    gap: 32px;
    align-items: start;
}

.default {
    height: 24px;
    width: 24px;
}

.md {
    height: 32px;
    width: 32px;
}

.lg {
    height: 48px;
    width: 48px;
}

.xl {
    height: 64px;
    width: 64px;
}

.header, .label {
    color: var(--storybook-text-color);
}
