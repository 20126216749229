.micBlock {
    margin-top: 10px;

    display: flex;

    @media only screen and (max-width: 750px) {
        margin: 0px 5%;
    }

    .micCard {
        text-align: left;
    }
    .audioWave {
        margin-top: 15px;
    }
    .buttonBlk {
        margin-right: 75%;
        margin-top: 13px;
    }

    .mic-card {
        max-width: 320px;
        margin-right: 43px;
        padding: var(--padding-2xl);
    }

    .speaking-testing-card {
        width: 100%;
    }

    .silence-check-card {
        flex: 1 1 auto;
        height: 450px;
    }
}
