.root {
    color: var(--text-color);
}

.heading {
    font-family: var(--heading-font);
}

.body {
    font-family: var(--body-font);
}

.heading-xs {
    font-size: var(--heading-font-size-xs);
    line-height: var(--heading-font-size-line-height-xs);
}

.heading-sm {
    font-size: var(--heading-font-size-sm);
    line-height: var(--heading-font-size-line-height-sm);
}

.heading-md {
    font-size: var(--heading-font-size-md);
    line-height: var(--heading-font-size-line-height-md);
}

.heading-lg {
    font-size: var(--heading-font-size-lg);
    line-height: var(--heading-font-size-line-height-lg);
}

.heading-xl {
    font-size: var(--heading-font-size-xl);
    line-height: var(--heading-font-size-line-height-xl);
}

.heading-xxl {
    font-size: var(--heading-font-size-xxl);
    line-height: var(--heading-font-size-line-height-xxl);
}

.body-xxs {
    font-size: var(--body-font-size-xss);
    line-height: var(--body-font-line-height-xss);
}

.body-xs {
    font-size: var(--body-font-size-xs);
    line-height: var(--body-font-line-height-xs);
}

.body-sm {
    font-size: var(--body-font-size-sm);
    line-height: var(--body-font-line-height-sm);
}

.body-md {
    font-size: var(--body-font-size-md);
    line-height: var(--body-font-line-height-md);
}

.body-lg {
    font-size: var(--body-font-size-lg);
    line-height: var(--body-font-line-height-lg);
}

.regular {
    font-weight: var(--font-regular);
}

.medium {
    font-weight: var(--font-medium);
}

.semibold {
    font-weight: var(--font-semibold);
}

.bold {
    font-weight: var(--font-bold);
}

.extrabold {
    font-weight: var(--font-extrabold);
}

.error {
    color: var(--primary-red);
}

.italic {
    font-style: italic;
}

.underlined {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

.disabled {
    color: var(--text-color-disabled);
}
