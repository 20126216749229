@import url("./assets/css/dark-theme.css");
@import url("./assets/css/light-theme.css");
@import url("./assets/css/contrast-theme.css");

@font-face {
  font-family: 'Work Sans';
  src: url(./assets/fonts/WorkSans.ttf) format(truetype);
}

@font-face {
  font-family: 'Open Sans';
  src: url(./assets/fonts/OpenSans.woff2) format(woff2);
  font-display: swap;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* storybook */

.docs-story,
.sb-show-main,
.sb-show-main body,
.sb-show-main #root {
  background-color: var(--storybook-bg-color);
}