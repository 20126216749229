.appBlock {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  -webkit-box-align: stretch;
  align-items: stretch;
  overflow: hidden;
  color: rgb(50, 50, 50);
  height: 100vh;
  background-color: var(--background-color);
}

h5 {
  margin: 0px;
}
.flex {
  display: flex;
}

.centerBlock {
  margin: 0px 5%;
  
}
.scrollSection {
  max-height: 80vh;
  overflow-y: auto;
}

.contentGrow {
  flex-grow: 1;
}

.precheckActions {
  justify-content: end;
  display: flex;
  gap: 22px;
  margin: 20px 0 10px 0;
}

label {
  font-weight: 700;
}

