@import url('variable.css');

.white_on_black {
    color: var(--text-color);
    .cardBlock {
        background-color: var(--dark-card-bgColor);
    }
    .svgFontColor{
        fill: var(--dark--font-color);
    }
    .timerColor, .sampleScreenBlockPart2 p {
        color: var(--dark--font-color) !important;
    }
    .testScreen .belowCardPart2 {
        background-color: var(--dark-card-bgColor);
    }
    .testScreen .timeMsgColor {
        color: var(--dark--font-color);
    }
    .progressBarColor{
        background: var(--dark--font-color);
    }
    .videoBgColor {
        background-color: var(--dark-bgColor);
    }
    
}

.yellow_on_black {
    color: var(--text-color);    
    .cardBlock  {
        background-color: var(--dark-card-bgColor)
    }
    .svgFontColor {
        fill: var(--yellow--font-color);
    }
    .timerColor, .sampleScreenBlockPart2 p {
        color: var(--yellow--font-color) !important;
    }
    .testScreen .belowCardPart2 {
        background-color: var(--dark-card-bgColor);
    }
    .testScreen .timeMsgColor {
        color: var(--yellow--font-color);
    }
    .progressBarColor{
        background: var(--yellow--font-color);
    }
    .videoBgColor {
        background-color: var(--dark-bgColor);
    }
} 
.sm {
    font-size: var(--body-font-size-sm);
    line-height: var(--body-font-line-height-sm);
    font-weight: 600;
    font-family: var(--body-font);
    h2 {
        font-size: var(--heading-font-size-sm);
        margin: 0;
        font-family: var(--heading-font);
    }
    h5 {
        font-size: var(--small-h5-fontSize);
    }
}

.md {
    font-size: var(--body-font-size-md);
    line-height: var(--body-font-line-height-md);
    font-weight: 400;
    font-family: var(--body-font);
    h2 {
        font-size: var(--heading-font-size-md);
        margin: 0;
        font-family: var(--heading-font);
    }
    h5 {
        font-size: var(--medium-h5-fontSize);
    }
    label {
        font-weight: 700;
    }
    .soundBlock {
        height: 60vh;
    }
    
}

.lg {
    font-size: var(--body-font-size-lg);
    line-height: var(--body-font-line-height-lg);
    font-family: var(--body-font);
    font-weight: 400;
    h2 {
        font-size: var(--heading-font-size-lg);
        margin: 0;
        font-family: var(--heading-font);
    }
    h5 {
        font-size: var(--large-h5-fontSize);
    }
    label {
        font-weight: 800;
    }
    .soundBlock {
        height: 63vh;
    }
    
}

.bolderString{
    font-weight: var(--font-bold);
}

.font-selection-container, .contrast-selection-container {
    margin-top: 10px;
  }
