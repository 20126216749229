.container {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1;
  padding: 5px;
  text-align: left;
  margin-bottom: 10px;
}

.card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.summaryAvatarContainer {
  height: auto;
  margin: 10px 0 50px 0;

  svg {
    max-height: 13vh;
    max-width: 15vh;
  }

  .middleSvg {
    svg {
      max-height: 16vh;
      max-width: 18vh;
    }
  }

  .imageBlock {
    text-align: center;
    aspect-ratio: 0;
    margin-top: 10px;
  }

  .coverImage {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .jc {
    justify-content: center;
  }

  .error {
    height: 422px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .error span {
    color: #BB281C;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    font-weight: 700;
  }

  .error .icon {
    margin-right: 8px;
  }

  .error .errorDetails {
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 23px;
  }


  .centerItem {
    display: flex;
    justify-content: center;
    margin: 8px;
  }

  @media only screen and (max-width: 750px) {
    margin: 0px 5%;
  }

  .finContentCard {
    max-width: fit-content;
    text-align: center;
    margin: auto;
  }

  .summaryContent {
    margin: auto;
    margin-left: 2vh;
  }

  .summaryAvatar {
    max-height: 30vh;
    border-radius: 50%;
  }

  .centerContent {
    margin-block: 2vh;
    text-align: center;
  }

  .videoBgColor {
    background-color: var(--default-bgColor);
  }

  .SoundTimerGrid {
    grid-template-columns: 1fr 7fr 1fr;

    .cardBlock {
      background-color: transparent;
    }
  }
  .videoCls {
    margin-top: 10px;
    border: 2px solid #ADABAB;
    border-radius: 5px;
    padding-inline: 5px;
  }
  .hoverPlayButton{
    cursor: pointer;
  }
}