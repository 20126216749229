.wrapper {
    height: 30px;
    width: 30px;
    border-radius: 50%;

    display: flex;
    align-items: center;

    &.inactive {
        border: 2px solid var(--stepper-icon-border-color);
        background-color: var(--stepper-icon-bg-color);
    }

    &.active {
        border: 2px solid var(--stepper-icon-active-border-color);
        background-color: var(--stepper-icon-active-bg-color);
    }
}

.iconBGInactive {
    fill: var(--stepper-icon-bg-color);
}

.iconBGActive {
    fill: var(--stepper-icon-active-bg-color);
}

.iconColorInactive {
    fill: var(--stepper-icon-color);
}

.iconColorActive {
    fill: var(--stepper-icon-active-color);
}

.wrapperContainer {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border: 2px solid;

    &.iconBorderInactive {
        border-color: transparent;
    }

    &.iconBorderActive {
        border-color: var(--stepper-icon-active-border-color);
    }
}
